import React from 'react'
import AboutUs from '../../components/AboutUs/AboutUs'
import Subscribe from '../../components/Subscribe/Subscribe' 

const AboutUS = () => {
  return (
    <div>
        <AboutUs/>
        <Subscribe/>
    </div>
  )
}

export default AboutUS