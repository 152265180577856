import React from 'react'
import Contact from '../../components/Contact/Contact';


const Contacts = () => {
  return (
    <section className="contact-wrapper">
    <Contact/>
    </section>
  )
}

export default Contacts