import React from 'react'
import LogIn from '../../components/LogIn/LogIn'

const LoginIn = () => {
  return (
  <div>
    <LogIn/>
  </div>

  )
}

export default LoginIn