import ceo1 from "../components/assets/Ceo1.png"
import ceo2 from '../components/assets/Ceo2.png'
import ceo3 from '../components/assets/Ceo3.png'

export const Test=[
    
    {

        cmnt:"On the other hand, we denounce with righteous indigation and dislike men who are so beguild and demoralized by the charms of pleasure",
        name: "Serhy Hipskyy",
        ocupation:"CEO Universal",
        image:ceo1

    },
    {
        cmnt:"On the other hand, we denounce with righteous indigation and dislike men who are so beguild and demoralized by the charms of pleasure",
        name: "Justus Maneke",
        ocupation:"CEO Uronaman",
        image:ceo2

    }
    ,{
        cmnt:"On the other hand, we denounce with righteous indigation and dislike men who are so beguild and demoralized by the charms of pleasure",
        name: "Britain Erikson",
        ocupation:"CEO Universal",
        image:ceo3
    },
    {

        cmnt:"On the other hand, we denounce with righteous indigation and dislike men who are so beguild and demoralized by the charms of pleasure",
        name: "Serhy Hipskyy",
        ocupation:"CEO Universal",
        image:ceo1

    },
    {
        cmnt:"On the other hand, we denounce with righteous indigation and dislike men who are so beguild and demoralized by the charms of pleasure",
        name: "Justus Maneke",
        ocupation:"CEO Uronaman",
        image:ceo2

    }
    ,{
        cmnt:"On the other hand, we denounce with righteous indigation and dislike men who are so beguild and demoralized by the charms of pleasure",
        name: "Britain Erikson",
        ocupation:"CEO Universal",
        image:ceo3
    }
]