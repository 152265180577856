import React from 'react'
import SignUp from '../../components/SignUp/SignUp'

const SignUpp = () => {
  return (
    <section className="Sign-wrapper">
        <SignUp/>
    </section>
  )
}

export default SignUpp